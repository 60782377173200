
  import { Component, Vue } from "vue-property-decorator";
  import { readEnrollments } from "@/store/main/getters";
  import { dispatchGetEnrollments } from "@/store/main/actions";
  import CourseCard from "@/components/CourseCard.vue";

  @Component({
    components: {
      CourseCard,
    },
  })
  export default class Dashboard extends Vue {
    get enrollments() {
      return readEnrollments(this.$store);
    }

    public async mounted() {
      await dispatchGetEnrollments(this.$store);
    }
  }
